<template>
  <div class="IssueList">
    <IssueListAppBar
      class="IssueList__app-bar"
      :project-id="projectId"
      :card-id="cardId"
      :sub-query="subQuery"
      :grouping-value="groupingValue"
      :selected-issues="selectedIssues"
    />

    <SplitLayout
      v-if="issues"
      :sizes="[60, 40]"
      :min-size="[300, 200]"
      :hide-right="!inlineIssueId"
      class="IssueList__split"
      local-storage-key-prefix="IssueList__split"
      :style="{
        height: $vuetify.breakpoint.mdAndUp
          ? 'calc(100vh - 64px)'
          : 'calc(100vh - 56px)'
      }"
    >
      <template #left>
        <IssueTableView
          v-model="selectedIssueIds"
          class="IssueList__issues"
          :project-id="projectId"
          :issues="issues"
          :card-id="cardId"
          :open-issue-id="inlineIssueId"
          @assign-from="assignFromIssue($event.issue)"
          @mass-assign="assignFromSelection"
        />
      </template>

      <template #right>
        <v-navigation-drawer
          class="IssueList__issue-drawer"
          :class="{ 'IssueList__issue-drawer--open': !!inlineIssueId }"
          :value="!!inlineIssueId"
          width="100%"
          :style="{
            transitionDuration: '200ms',
            transitionTimingFunction: 'ease-in-out',
            maxHeight: `calc(100vh - ${$vuetify.breakpoint.mdAndUp ? 64 : 56}px)`
          }"
          stateless
          right
        >
          <InlineIssue
            v-if="!!inlineIssueId"
            :issue-id="inlineIssueId"
            :project-id="projectId"
          />
        </v-navigation-drawer>
      </template>
    </SplitLayout>

    <AssigneeDialog
      v-if="projectId"
      :project-id="projectId"
      :issues="assigneeDialogIssues"
      @update:issues="$event.length === 0 && (assigneeDialog = false)"
    />

    <!-- Snackbar action to choose integration for selected tasks -->
    <Portal
      v-if="selectedIssueIds.length > 0 &&
        (activeProjectIntegrations || []).length > 0 && !exportTasksDialogIsOpen"
      to="snackbar-action"
    >
      <div class="d-flex align-center">
        <v-tooltip
          top
          :disabled="!selectedIssueIds || selectedIssueIds.length <= MAX_MASS_EXPORT_TASK"
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              <ConditionalMenu
                :show-menu="activeProjectIntegrations.length > 1"
                top
                offset-y
                :disabled="selectedIssueIds && selectedIssueIds.length > MAX_MASS_EXPORT_TASK"
                @click-no-menu="createTasks({ integration: activeProjectIntegrations[0] })"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="IssueList__snackbar-task-btn px-2"
                    text
                    tile
                    height="40"
                    color="#69D2EF"
                    :disabled="selectedIssueIds && selectedIssueIds.length > MAX_MASS_EXPORT_TASK"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'$jira'" />
                    <span
                      class="ml-2"
                      v-text="$t('issue.AddTask')"
                    />
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="integration in activeProjectIntegrations"
                    :key="integration.id"
                    class="pl-2"
                    :ripple="{ class: 'app-ripple' }"
                    style="height: 36px"
                    @click="createTasks({ integration })"
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon v-text="integration.integrationType.assets.icon" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-text="integration.name" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </ConditionalMenu>
            </div>
          </template>

          <span v-text="$t('issue.MaxOfNIssuesM', { maxCount: MAX_MASS_EXPORT_TASK })" />
        </v-tooltip>

        <v-btn
          class="IssueList__snackbar-task-btn px-2"
          text
          tile
          height="40"
          color="#69D2EF"
          @click="openCsvDialog"
        >
          <v-icon v-text="'mdi-file-chart-outline'" />
          <span
            class="ml-2"
            v-text="$t('report.GenerateReport')"
          />
        </v-btn>
      </div>
    </Portal>
  </div>
</template>

<script>
import { ConditionalMenu, SplitLayout } from '@hexway/shared-front'
import * as R from 'ramda'

import {
  CARD_TYPE,
  EMPTY_SET,
  NON_FILTER_PARAMS,
  ISSUES_POLL_INTERVAL,
  PROJECT_PERMISSION_LEVEL as PERM,
} from '../constants'
import { reportError, issueFilterQueryToObject, dotPath, mergeIssueFilters, replaceRoute } from '../helpers'

import Dashboard from '../store/orm/dashboard'
import DashboardCard from '../store/orm/dashboardCard'
import Dialog from '../store/orm/dialog'
import Issue from '../store/orm/issue'
import IssueCounter from '../store/orm/issueCounter'
import IssueStatusChange from '../store/orm/issueStatusChange'
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'
import SlaConfig from '../store/orm/slaConfig'

import AssigneeDialog from '../components/AssigneeDialog'
import InlineIssue from '../components/InlineIssue'
import IssueListAppBar from '../components/IssueListAppBar'
import IssueTableView from '../components/IssueTableView'

export default {
  name: 'IssueList',

  components: {
    AssigneeDialog,
    ConditionalMenu,
    InlineIssue,
    IssueListAppBar,
    IssueTableView,
    SplitLayout,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title('Issues'),
    }
  },

  beforeRouteLeave(to, from, next) {
    // preserve issue list when navigating
    // `IssueList` -> `IssueList` (filters changed)
    // `IssueList` -> `Issue` -> `IssueList` (to prevent redrawing table)
    // see also `IssueTable.watch.$route` and `<keep-alive />` in `DefaultLayout`
    const preserveIssuesRoutes = [
      'IssueList',
      'IssueListIssue',
      'CardIssueList',
      'CardIssueListIssue',
      'ProjectIssueList',
      'ProjectIssueListIssue',
      'ProjectCardIssueList',
      'ProjectCardIssueListIssue',
    ]
    if (!preserveIssuesRoutes.includes(to.name) || from?.params?.projectId !== to.params.projectId) {
      Issue.deleteAll()
      IssueStatusChange.deleteAll()
      IssueCounter.deleteAll()
      this.$store.commit('$snackbar/hide')
      this.selectedIssueIds = []
    }
    next()
  },

  props: {
    // issues are always bound to a project, but may be requested outside of project (via global dashboard)
    projectId: { type: String, default: null },
    // for browsing issues inside the `card` with the give `cardId`
    cardId: { type: Number, default: null },
    // to open inline issue preview with the given `inlineIssueId`
    inlineIssueId: { type: String, default: null },
    // to apply filters when loading issues
    filter: { type: Object, default: null },
    // only relevant if there is a valid `cardId`,
    // when performing drill-down for `pie_chart`
    // `groupingValue` is the value of the chosen sector,
    // e.g. '1' means `criticalityScore=1` was clicked
    // (assuming pie chart was grouped by criticalityScore)
    groupingValue: { type: String, default: null },
    // relevant when there's a valid `cardId`,
    // when performing drill-down for `ab` card
    // `subQuery` is a path to the query, by default `card.query` is taken,
    // for `ab` card it can be 'subQuery'
    // to narrow filters by `card.query.subQuery`
    subQuery: { type: String, default: '' },
  },

  data: () => ({
    MAX_MASS_EXPORT_TASK: 20,

    // issue ids checked with checkboxes
    selectedIssueIds: [],

    assigneeDialogIssueIds: null,
    assigneeDialog: false,

    customSnackbarPayload: null,

    pollIntervalId: null,
  }),

  computed: {
    dashboardId() { return this.projectId || Dashboard.GLOBAL_ID },

    selectedIssues() {
      const { selectedIssueIds } = this
      return Issue.query().withAllRecursive().findIn(selectedIssueIds)
    },

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    allProjectIds() {
      return Project.query().orderBy('id').all().map(p => p.id)
    },

    currentUser() { return this.$store.getters['user/current'] },

    canLoadIntegration() {
      const { currentUser, projectId, project } = this
      if (!projectId) return null // inapplicable on cross-project dashboard
      if (currentUser?.isAdmin) return true
      if (!currentUser || !project) return null
      return currentUser.isAdmin || [PERM.OWNER, PERM.EDITOR, PERM.READONLY]
        .map(perm => perm.value)
        .includes(project.permission)
    },

    miniDrawer: {
      get() { return this.$store.state.appDrawer.miniDrawer },
      set(miniDrawer) {
        this.$store.commit('appDrawer/setMiniDrawer', miniDrawer)
        return !!miniDrawer
      },
    },

    isOnOwnRoute() { // on list/table view right now
      // may be different route BTW (this view is inside `<keep-alive />`)
      return this.checkIsOwnRoute(this.$route)
    },

    card() {
      const { cardId } = this
      return cardId && DashboardCard.find(cardId)
    },

    issues() {
      const { projectId } = this
      const q = Issue.query().withAllRecursive()
      return projectId
        ? q.where('projectID', projectId || null).all()
        : q.all()
    },

    queryDotPath() {
      const { card } = this
      if (!card) return null

      return {
        [CARD_TYPE.counter]: 'query.query',
        [CARD_TYPE.aOfB]: 'query.mainQuery',
        [CARD_TYPE.pieChart]: 'query.query',
        [CARD_TYPE.table]: 'query.query',
      }[card.cardType] || null
    },

    cardFilter() { // filters for the current card
      const { card, queryDotPath, groupingValue, subQuery } = this
      if (!card) return null

      // `queryPath` and `groupingValue` may affect
      // selected filters for drill-down
      let filter = issueFilterQueryToObject(dotPath(queryDotPath, card))

      if (groupingValue) {
        // for pie chart drill-down, filters based on the clicked chart sector
        if (card.cardType === CARD_TYPE.pieChart) {
          const field = card.query.groupBy
          const narrowFilterBy = { [field]: groupingValue }
          filter = mergeIssueFilters(filter, narrowFilterBy)
        }

        // for table card drill-down, filters based on the clicked cell
        if (card.cardType === CARD_TYPE.table) {
          try {
            const [xValue, yValue] = groupingValue.split('~')
              .map(JSON.parse.bind(JSON))

            const narrowFilterBy = {}
            const [xProp, yProp] = [card.query.xAxis, card.query.yAxis]
            if (xValue !== null) narrowFilterBy[xProp] = String(xValue)
            if (yValue !== null) narrowFilterBy[yProp] = String(yValue)
            filter = mergeIssueFilters(filter, narrowFilterBy)
          } catch (e) {
            reportError(e)
          }
        }
      }

      if (subQuery) {
        // for a/b drill-down, narrow query by subQuery
        const narrowFilterBy = issueFilterQueryToObject(dotPath(`query.${subQuery}`, card))
        filter = mergeIssueFilters(filter, narrowFilterBy)
      }

      return filter
    },

    // issues for assignees dialog
    assigneeDialogIssues() {
      const {
        projectId,
        assigneeDialogIssueIds: issueIds,
        assigneeDialog,
      } = this

      if (!assigneeDialog || !projectId) return []
      return Issue.query().withAllRecursive().whereIdIn(issueIds).all()
    },

    csvExportDialogIsOpen() {
      return Dialog
        .query()
        .where('componentName', 'CsvExportIssuesDialog')
        .where('isOpen', true)
        .exists()
    },

    exportTasksDialogIsOpen() {
      return Dialog
        .query()
        .where('componentName', 'ExportTasksDialog')
        .orWhere('componentName', 'ExportTasksCreateDialog')
        .orWhere('componentName', 'ExportTasksLinkDialog')
        .where('isOpen', true)
        .exists()
    },

    projectIntegrations() {
      const { $store, projectId } = this
      return projectId && $store.getters['integration/forProject'](projectId)
    },

    activeProjectIntegrations() {
      const { projectIntegrations } = this
      return projectIntegrations && R.pipe(
        R.reject(integration => integration.isDraft),
        R.sortWith([
          R.ascend(integration => integration.name?.toLowerCase?.()),
          R.ascend(R.prop('name')),
        ]),
      )(projectIntegrations)
    },
  },

  watch: {
    $route: {
      handler($route, previousRoute) {
        // this view is persistent, load data only for relevant routes
        const onIssues = this.isOnOwnRoute

        const wasOnIssues = !!previousRoute && this.checkIsOwnRoute(previousRoute)

        const filtersAndCard = R.pipe(
          R.prop('query'),
          R.omit(['issueId', ...NON_FILTER_PARAMS]),
        )
        const queryOrParamsChanged = !previousRoute || !R.equals(
          filtersAndCard($route),
          filtersAndCard(previousRoute),
        ) || !R.equals(
          $route?.params,
          previousRoute?.params,
        )

        // when query/card changes:
        // 1) load new issues for the query+card
        // 2) reset selected rows
        if (onIssues && (!wasOnIssues || queryOrParamsChanged)) {
          this.loadData()
          this.selectedIssueIds = []
        }

        if (this.isOnOwnRoute) {
          document.body.classList.add('body-horizontal-scroll')
        } else {
          document.body.classList.remove('body-horizontal-scroll')
        }
      },
      immediate: true,
    },

    canLoadIntegration: {
      immediate: true,
      handler(canLoad) {
        if (canLoad) this.loadIntegrations({ reload: false })
      },
    },

    // collapse drawer with filters on inline issue has been opened
    inlineIssueId: {
      async handler(issueId) {
        if (issueId) this.miniDrawer = true
      },
      immediate: true,
    },

    miniDrawer: {
      async handler(isCollapsed, wasCollapsed) {
        await this.$nextTick()
        // when drawer is expanded and there's an issue preview: hide it
        if (this.inlineIssueId && !isCollapsed && wasCollapsed) {
          const backToListRoute = R.pipe(
            R.dissocPath(['query', 'issueId']),
            R.pick(['name', 'params', 'query']),
          )(this.$route)
          await replaceRoute(this.$router, backToListRoute)
        }
      },
    },

    projectId: {
      handler(projectId) {
        if (projectId) {
          Project.dispatch('$getOne', { projectId, reload: false })
          SlaConfig.dispatch('$getForProject', { projectId, reload: false })
        }
      },
      immediate: true,
    },

    allProjectIds: {
      handler(projectIds) {
        if (this.projectId) return
        projectIds.forEach(projectId => {
          this.$store.dispatch('issueSchema/get', { projectId, reload: false })
          this.$store.dispatch('$issueStatus/getList', { projectId, reload: false })
          SlaConfig.dispatch('$getForProject', { projectId })
        })
      },
      immediate: true,
    },

    customSnackbarPayload: 'toggleAssigneeSnackbar',
    selectedIssueIds: 'toggleAssigneeSnackbar',
    assigneeDialog: 'toggleAssigneeSnackbar',
    exportTasksDialogIsOpen: 'toggleAssigneeSnackbar',
    csvExportDialogIsOpen: 'toggleAssigneeSnackbar',
  },

  created() {
    this.startPolling()
  },

  activated() {
    this.startPolling()
  },

  beforeDestroy() {
    this.stopPolling()
  },

  deactivated() {
    this.stopPolling()
  },

  methods: {
    async loadData() {
      const { $store, cardId, projectId } = this
      try {
        if (cardId) await this.loadDashboard()
        await Promise.all([
          this.loadProjectsAndGroup({ reload: false }),
          this.loadIssues(),
          ...(projectId ? [
            this.loadIssueStatuses(),
            this.loadPermissions(),
            this.loadIntegrations(),
            this.loadSchema().then((schema) => schema != null && this.loadCounters()),
          ] : []),
          $store.dispatch('user/getList'),
        ])
      } catch (e) {
        reportError(e).catch(() => { /* pass */ })
      }
    },

    loadProjectsAndGroup({ reload = false } = {}) {
      return Promise.all([
        ProjectGroup.dispatch('$getTree', { reload }),
        Project.dispatch('$getList', { reload }),
      ])
    },
    loadDashboard(payload = { reload: false }) {
      const { dashboardId } = this
      return Dashboard.dispatch('$get', { id: dashboardId, ...payload })
    },
    loadIssues() {
      const {
        projectId,
        cardFilter,
        filter,
      } = this

      let requestFilter
      if (cardFilter === EMPTY_SET && !Object.values(filter || {}).length) {
        requestFilter = EMPTY_SET
      } else if (cardFilter !== null) {
        requestFilter = R.mergeDeepRight(cardFilter, filter || {})
      } else {
        requestFilter = filter || {}
      }
      if (requestFilter !== EMPTY_SET) {
        requestFilter = R.filter(Boolean, requestFilter)
      }

      return Issue.dispatch('$getList', {
        projectId: projectId || null,
        filter: requestFilter,
      })
    },
    loadIssueStatuses() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot load issue status without project context')
      return $store.dispatch('$issueStatus/getList', { projectId, reload: false })
    },
    loadSchema() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot load issue schema without project context')
      return $store.dispatch('issueSchema/get', { projectId, reload: false })
    },
    loadPermissions() {
      const { $store, projectId } = this
      if (!projectId) throw new Error('Cannot load permissions without project context')
      return $store.dispatch('permission/getProjectPermissions', { projectId })
    },
    loadIntegrations(payload = {}) {
      const { $store, projectId, canLoadIntegration } = this

      if (!canLoadIntegration) return
      return $store.dispatch('integration/getForProject', { ...payload, projectId })
    },
    loadCounters(filter = {}) {
      // see $issueStatus Vuex-module && `getIssuesCount`
      const { projectId } = this

      if (!projectId) throw new Error('Cannot load counters without project context')
      return IssueCounter.dispatch('$countCommon', { projectId, filter, cancellable: false })
    },

    async fetchIssuesTick() {
      if (!this.pollIntervalId) return
      await this.loadIssues()
    },
    stopPolling() {
      clearTimeout(this.pollIntervalId)
      this.pollIntervalId = null
    },
    startPolling() {
      if (this.pollIntervalId) return
      this.pollIntervalId = setInterval(this.fetchIssuesTick, ISSUES_POLL_INTERVAL)
    },

    checkIsOwnRoute(route) { // on list/table view
      // may be different BTW (this view is inside `<keep-alive />`)
      return [
        'IssueList',
        'CardIssueList',
        'ProjectIssueList',
        'ProjectCardIssueList',
      ].includes(route.name)
    },

    toggleAssigneeSnackbar() {
      const {
        $store,
        projectId,
        selectedIssueIds: { length: n },
        assigneeDialog,
        exportTasksDialogIsOpen,
        csvExportDialogIsOpen,
      } = this

      if (this.customSnackbarPayload) {
        $store.commit('$snackbar/setMessage', this.customSnackbarPayload)
        if (this.customSnackbarPayload.timeout !== -1) {
          setTimeout(
            () => { this.customSnackbarPayload = null },
            this.customSnackbarPayload.timeout || 10000,
          )
        }
      } else if (!projectId || assigneeDialog || exportTasksDialogIsOpen || csvExportDialogIsOpen) {
        $store.commit('$snackbar/hide')
      } else if (n > 0) {
        $store.commit('$snackbar/setMessage', {
          message: this.$tc('layout.SelectedN', n),
          action: {
            type: 'function',
            label: this.$t('issue.Assign'),
            icon: 'mdi-account-circle-outline',
            fn: () => {
              this.assigneeDialogIssueIds = this.selectedIssueIds
              this.assigneeDialog = this.assigneeDialogIssueIds.length > 0
            },
          },
          actionPortal: true,
          onCloseManually: () => {
            this.selectedIssueIds = []
          },
          timeout: -1,
          persistent: true,
          bottom: true,
        })
      } else {
        if (this.assigneeDialogIssueIds == null) this.assigneeDialog = false
        $store.commit('$snackbar/hide')
      }
    },

    assignFromSelection() {
      this.assigneeDialogIssueIds = this.selectedIssueIds
      this.assigneeDialog = this.assigneeDialogIssueIds.length > 0
    },

    // opens assignee dialog from an issue button
    assignFromIssue(issue) {
      // if issue is NOT selected: clear selection
      // and open dialog only for that issue
      if (!this.selectedIssueIds.includes(issue.id)) {
        this.assigneeDialogIssueIds = [issue.id]
        this.selectedIssueIds = []
      }

      // otherwise: just open the dialog
      this.assigneeDialog = true
    },

    // create external tasks via the provided integration
    // `issue`: if selected from the issue row
    async createTasks({ integration, issue = null }) {
      const { selectedIssueIds } = this

      if (!issue && !selectedIssueIds.length) {
        throw new Error('No issues to export')
      }

      // if issue is NOT selected: clear selection
      // and open dialog only for that issue
      let issueIds
      if (issue != null && !selectedIssueIds.includes(issue.id)) {
        issueIds = [issue.id]
        this.selectedIssueIds = []
      } else {
        issueIds = selectedIssueIds
      }

      await this.$nextTick()
      await this.openExportTasksDialog(integration.id, issueIds)
    },

    openExportTasksDialog(integrationId, issueIds) {
      return Dialog.open({
        componentName: 'ExportTasksDialog',
        props: {
          integrationId,
          projectId: this.projectId,
          issueIds,
        },
        listeners: {
          linked: (e) => {
            this.selectedIssueIds = []
            if (e?.showSnackbar) {
              this.customSnackbarPayload = e.showSnackbar
            }
          },
          created: (e) => {
            if (!(e?.hasErrors)) {
              this.selectedIssueIds = []
            }
            if (e?.showSnackbar) {
              this.customSnackbarPayload = e.showSnackbar
            }
          },
        },
      })
    },

    openCsvDialog() {
      const { projectId, selectedIssueIds } = this
      Dialog.open({
        componentName: 'CsvExportIssuesDialog',
        props: { projectId, selectedIssueIds },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

@function trans($prop, $duration: 200ms, $timing: ease-in-out)
  @return $prop $duration $timing

.IssueList
  &__split
    max-height: 100%
    overflow: hidden

  &__issues
    flex-grow: 1
    transition: trans(flex-basis), trans(max-width) !important
    will-change: flex-basis, max-width

  &__issue-drawer
    flex: 0 0 0
  &__issue-drawer--open
    transition: trans(flex-basis) !important
    flex: 0 0 540px

    @media #{map-get($display-breakpoints, 'md-and-down')}
      flex: 0 0 400px

  &__snackbar-task-btn
    ::v-deep .v-btn__content
      font-size: 14px
      font-weight: 500
      line-height: 20px
      letter-spacing: 0.005em

</style>

<style lang="sass">
  @import '../scss/mixins'
  @import '../scss/variables'

  body.body-horizontal-scroll
    overflow-x: auto
</style>
